import { Select, TextField } from '@shopify/polaris'
import { Field } from '@shopify/react-form'
import { FunctionComponent } from 'react'
import { selectFormatter } from 'store/global/global.selectors'
import { _ } from 'store/hooks'
import {
  selectRuleActionOptionsReturnTypes,
  selectRuleActionOptionsShippingTypes,
  selectRuleActions,
} from 'store/rules/rules.selectors'
import styled from 'styled-components'

import { RuleAction, RuleActionOption, useWarehousesQuery } from 'gql'

import { getDateMask } from './utils'

const PLACEHOLDER_OPTIONS = {
  EMPTY: { label: '', value: '' },
}

interface TakeActionRowProps {
  action: Field<any>
  actionOption: Field<any>
  warehouseId: Field<any>
  value: Field<any>
  showAdjustWindowAction: boolean
  showApprovalActions: boolean
  showDoNotRequireShippingAction: boolean
}

const TakeActionRow: FunctionComponent<TakeActionRowProps> = ({
  action,
  actionOption,
  warehouseId,
  value,
  showAdjustWindowAction,
  showApprovalActions,
  showDoNotRequireShippingAction,
}) => {
  const fmt = _(selectFormatter)

  const ruleActions = _(selectRuleActions)
  const ruleActionOptionReturnTypes = _(selectRuleActionOptionsReturnTypes)
  const ruleActionOptionShippingTypes = _(selectRuleActionOptionsShippingTypes)
  const [{ data }] = useWarehousesQuery()
  const warehouses = data?.warehouses
  const dateMask = getDateMask(value.value)

  let actionSelectOptions = Object.values(ruleActions)
  if (!showAdjustWindowAction)
    actionSelectOptions = actionSelectOptions.filter(
      (a) => ![RuleAction.AdjustWindow, RuleAction.AllowReturnsUntilDate].includes(a.value),
    )

  if (!showApprovalActions)
    actionSelectOptions = actionSelectOptions.filter(
      (a) => !(a.value == RuleAction.RequireApproval || a.value == RuleAction.DoNotRequireApproval),
    )

  if (!showDoNotRequireShippingAction)
    actionSelectOptions = actionSelectOptions.filter((a) => a.value !== RuleAction.DoNotRequireReturnShipping)

  return (
    <Content>
      <Select
        label={fmt('takeActionRow.placeholder')}
        labelHidden
        placeholder={fmt('takeActionRow.placeholder')}
        name="action"
        options={actionSelectOptions}
        {...action}
      />

      {[RuleAction.DenyReturn, RuleAction.AdjustWindow, RuleAction.AllowReturnsUntilDate].includes(action.value) && (
        <Select
          name="actionOption"
          label={fmt('global.returnType')}
          labelHidden
          placeholder={fmt('global.returnType')}
          options={Object.values(ruleActionOptionReturnTypes)}
          {...(actionOption as any)}
        />
      )}

      {action.value === RuleAction.DenyShipping && (
        <Select
          name="actionOption"
          label={fmt('global.shippingMethod')}
          labelHidden
          placeholder={fmt('global.shippingMethod')}
          options={Object.values(ruleActionOptionShippingTypes)}
          {...(actionOption as any)}
        />
      )}

      {action.value === RuleAction.ShipToWarehouse && (
        <Select
          label={fmt('global.warehouse')}
          labelHidden
          placeholder={fmt('global.warehouse')}
          name="warehouseId"
          options={
            warehouses
              ? warehouses.map((w) => ({
                  label: w?.name ?? '',
                  value: w?.id ?? '',
                }))
              : Object.values(PLACEHOLDER_OPTIONS)
          }
          {...warehouseId}
        />
      )}

      {action.value === RuleAction.AdjustWindow && (
        <TextField
          label={fmt('global.days')}
          labelHidden
          name="value"
          placeholder={fmt('global.days')}
          autoComplete="off"
          {...value}
        />
      )}

      {action.value === RuleAction.AllowReturnsUntilDate && (
        <TextField
          type="text"
          label={fmt('global.date')}
          labelHidden
          name="value"
          placeholder={fmt('global.YYYY-MM-DD')}
          autoComplete="off"
          value={dateMask.value}
          onChange={(val) => {
            dateMask.value = val
            value.onChange(val)
          }}
          error={value.error}
        />
      )}

      {action.value === RuleAction.SetFee && (
        <>
          <Select
            name="actionOption"
            label={fmt('global.returnType')}
            labelHidden
            placeholder={fmt('global.returnType')}
            options={[
              {
                label: fmt('actionOptions.returnTypes.refunds'),
                value: RuleActionOption.Refund,
              },
              {
                label: fmt('actionOptions.returnTypes.exchanges'),
                value: RuleActionOption.Exchange,
              },
              {
                label: fmt('actionOptions.returnTypes.storeCredits'),
                value: RuleActionOption.Credit,
              },
              {
                label: fmt('actionOptions.returnTypes.allReturnTypes'),
                value: RuleActionOption.All,
              },
            ]}
            {...(actionOption as any)}
          />
          <TextField
            label={fmt('global.fee')}
            labelHidden
            placeholder={fmt('global.fee')}
            name="value"
            autoComplete="off"
            {...value}
          />
        </>
      )}

      {action.value === RuleAction.SetShippingFee && (
        <>
          <Select
            name="actionOption"
            label={fmt('global.shippingMethod')}
            labelHidden
            placeholder={fmt('global.shippingMethod')}
            options={[
              {
                label: fmt('actionOptions.shippingTypes.prepaidShipping'),
                value: RuleActionOption.Free,
              },
              {
                label: fmt('actionOptions.shippingTypes.customerPaidShipping'),
                value: RuleActionOption.Paid,
              },
              {
                label: fmt('actionOptions.shippingTypes.customerSelfShip'),
                value: RuleActionOption.Self,
              },

              {
                label: fmt('actionOptions.shippingTypes.allShippingMethods'),
                value: RuleActionOption.All,
              },
            ]}
            {...(actionOption as any)}
          />
          <TextField
            label={fmt('global.fee')}
            labelHidden
            name="value"
            placeholder={fmt('global.fee')}
            autoComplete="off"
            {...value}
          />
        </>
      )}
    </Content>
  )
}

export default TakeActionRow

const Content = styled.div`
  display: flex;
  gap: var(--p-space-400);
  > * {
    flex: 1 1 auto;
  }
`
