import { Button, Icon, Select, TextField, useBreakpoints } from '@shopify/polaris'
import { MinusCircleIcon, PlusCircleIcon } from '@shopify/polaris-icons'
import { FieldDictionary } from '@shopify/react-form'
import { FunctionComponent, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  selectCountryOptions,
  selectFormatter,
  selectReturnTypeOptions,
  selectShopReasons,
} from 'store/global/global.selectors'
import { _ } from 'store/hooks'
import { selectGetValidOperators, selectRuleAttributes } from 'store/rules/rules.selectors'
import styled, { css } from 'styled-components'

import { getAttributeType } from 'myconstants'
import { notEmpty } from 'utils'

import { getDateMask } from './utils'

interface ConditionRowProps {
  condition: FieldDictionary<any>
  addCondition?: () => void
  removeCondition?: () => void
}

const ConditionRow: FunctionComponent<ConditionRowProps> = ({ condition, addCondition, removeCondition }) => {
  const fmt = _(selectFormatter)
  const ruleAttributes = _(selectRuleAttributes)
  const returnTypeOptions = _(selectReturnTypeOptions)
  const reasons = _(selectShopReasons)
  const countryOptions = _(selectCountryOptions)
  const localCountryOptions = useMemo(() => countryOptions.filter((co) => co.value), [countryOptions])
  const getValidOperators = _(selectGetValidOperators)
  const valueType = getAttributeType(condition.attr.value)

  const dateMask = getDateMask(condition.value.value)

  const { mdDown } = useBreakpoints()

  return (
    <Condition $wrap={mdDown}>
      <Attribute $fullWidth={mdDown}>
        <Select
          label={<FormattedMessage id="conditionRow.ruleAttribute" defaultMessage="Rule attribute" />}
          labelHidden
          name="attr"
          options={Object.values(ruleAttributes)}
          {...condition.attr}
        />
      </Attribute>
      <Operation>
        <Select
          label={fmt('conditionRow.condition')}
          labelHidden
          placeholder={fmt('conditionRow.condition')}
          name="op"
          options={getValidOperators(condition.attr.value, valueType)}
          {...condition.op}
        />
      </Operation>
      <Value>
        {valueType === 'date' && (
          <TextField
            type="text"
            label={fmt('global.value')}
            labelHidden
            placeholder={fmt('global.YYYY-MM-DD')}
            name="val"
            autoComplete="off"
            value={dateMask.value}
            onChange={(val) => {
              dateMask.value = val
              condition.value.onChange(val)
            }}
          />
        )}
        {valueType === 'country' && (
          <Select
            label={fmt('global.country')}
            labelHidden
            placeholder={fmt('global.country')}
            name="val"
            options={localCountryOptions}
            {...condition.value}
          />
        )}
        {valueType === 'return-type' && (
          <Select
            label={fmt('global.returnType')}
            labelHidden
            placeholder={fmt('global.returnType')}
            name="val"
            options={returnTypeOptions}
            {...condition.value}
          />
        )}
        {valueType === 'return-reason' && (
          <Select
            label={fmt('global.returnReason')}
            labelHidden
            placeholder={fmt('global.returnReason')}
            name="val"
            options={reasons?.filter(notEmpty).map((r) => r.reason) || []}
            {...condition.value}
          />
        )}
        {['text', 'number', 'email'].includes(valueType) && (
          <TextField
            type={valueType as 'text' | 'number' | 'email'}
            label={fmt('global.value')}
            labelHidden
            placeholder={fmt('global.value')}
            name="val"
            autoComplete="off"
            {...condition.value}
          />
        )}
      </Value>
      <Buttons>
        {removeCondition && (
          <div data-cy="removeRuleConditionButton">
            <Button
              icon={<Icon source={MinusCircleIcon} />}
              size="large"
              variant="tertiary"
              onClick={() => removeCondition()}
            />
          </div>
        )}
        {addCondition && (
          <div data-cy="addRuleConditionButton">
            <Button
              icon={<Icon source={PlusCircleIcon} />}
              size="large"
              variant="tertiary"
              onClick={() => addCondition()}
            ></Button>
          </div>
        )}
      </Buttons>
    </Condition>
  )
}

const Condition = styled.div<{ $wrap: boolean }>`
  display: flex;
  gap: var(--p-space-200);
  align-items: flex-end;
  ${(p) =>
    p.$wrap &&
    css`
      flex-wrap: wrap;
    `}
`

const Attribute = styled.div<{ $fullWidth: boolean }>`
  width: 0;
  flex: 0 1 40%;
  ${(p) =>
    p.$fullWidth &&
    css`
      flex: 0 0 100%;
    `}
`
const Operation = styled.div`
  width: 0;
  flex: 0 1 170px;
  @media (max-width: 600px) {
    flex-basis: 90px;
  }
`
const Value = styled.div`
  width: 0;
  flex: 1 0 170px;
`

const Buttons = styled.div`
  width: 0;
  flex: 0 0 56px;
  display: flex;
  gap: var(--p-space-300);

  .Polaris-Icon {
    transform: scale(1.15);
  }
`

export default ConditionRow
