import {
  Bleed,
  BlockStack,
  Box,
  Button,
  Checkbox,
  Divider,
  FormLayout,
  InlineError,
  InlineStack,
  Modal,
  Text,
  TextField,
} from '@shopify/polaris'
import { FormError, notEmpty, useDynamicList, useField, useForm } from '@shopify/react-form'
import { FunctionComponent, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { selectFormatter } from 'store/global/global.selectors'
import { showToast } from 'store/global/global.slice'
import { _, useAppDispatch } from 'store/hooks'
import { selectRuleActionOptionsReturnTypes, selectRuleActionOptionsShippingTypes } from 'store/rules/rules.selectors'

import {
  Condition,
  ConditionInput,
  Rule,
  RuleAction,
  RuleActionOption,
  RuleAttribute,
  RuleInput,
  RuleWhen,
  useReturnRuleCreateMutation,
  useReturnRuleDeleteMutation,
  useReturnRuleUpdateMutation,
} from 'gql'
import { log } from 'utils'

import ConditionRow from './ConditionRow'
import TakeActionRow from './TakeActionRow'

export interface EditRuleFormProps {
  rule?: Rule
  showAdjustWindowAction: boolean
  showApprovalActions: boolean
  showDoNotRequireShippingAction: boolean
  onComplete: () => void
}

const EditRuleModal: FunctionComponent<EditRuleFormProps> = ({
  rule,
  showAdjustWindowAction,
  showApprovalActions,
  showDoNotRequireShippingAction,
  onComplete,
}) => {
  const dispatch = useAppDispatch()
  const fmt = _(selectFormatter)
  const validActionOptionsReturnTypes = Object.keys(_(selectRuleActionOptionsReturnTypes))
  const validActionOptionsShippingTypes = Object.keys(_(selectRuleActionOptionsShippingTypes))
  const [, updateReturnRule] = useReturnRuleUpdateMutation()
  const [, createReturnRule] = useReturnRuleCreateMutation()
  const [{ fetching: deleting }, deleteReturnRule] = useReturnRuleDeleteMutation()

  const handleDeleteRule = async () => {
    if (rule?.id) {
      await deleteReturnRule({ id: rule.id })
      dispatch(showToast(fmt('rules.ruleDeleted')))
      return onComplete()
    }
  }

  // Conditions
  const emptyCondition = {
    id: '',
    uuid: '',
    attr: RuleAttribute.None,
    op: '',
    value: '',
  }
  const emptyConditionFactory = () => emptyCondition
  const {
    fields: conditionFields,
    addItem: addCondition,
    removeItem: removeCondition,
  } = useDynamicList(rule ? (rule.conditions as Condition[]) : [emptyCondition], emptyConditionFactory)

  const name = useField({
    value: rule?.name ?? '',
    validates: [notEmpty(fmt('global.nameRequired'))],
  })
  const when = useField(rule?.when ?? RuleWhen.And)

  const action = useField({
    value: rule?.action,
    validates: (takeActionValue) => {
      if (!takeActionValue) {
        return fmt('editRule.selectAction')
      }
      return ''
    },
  })
  const actionOption = useField(rule?.actionOption)
  const warehouseId = useField(rule?.warehouseId ?? '')
  const value = useField(rule?.value ? rule.value.toString() : '')
  const message = useField(rule?.message)

  const { fields, submit, submitErrors } = useForm({
    fields: {
      name,
      when,
      action,
      actionOption,
      warehouseId,
      value,
      message,
    },
    onSubmit: async (submittedData) => {
      const { name, when, action, actionOption, warehouseId, value, message } = submittedData

      const errors: FormError[] = []

      // For conditions, make sure each part has a value
      const validConditionFields = conditionFields.filter((f) => f.op.value && f.value.value)
      if (validConditionFields.length !== conditionFields.length) {
        errors.push({
          field: ['conditions'],
          message: fmt('editRule.invalidConditions'),
        })
      }

      // Action option required for certain actions
      let validActionOptions: string[] = []
      if (action) {
        if ([RuleAction.DenyReturn, RuleAction.AdjustWindow].includes(action)) {
          validActionOptions = validActionOptionsReturnTypes
        }
        if ([RuleAction.DenyShipping].includes(action)) {
          validActionOptions = validActionOptionsShippingTypes
        }
      }
      if (validActionOptions.length && (!actionOption || !validActionOptions.includes(actionOption))) {
        errors.push({
          field: ['actionOption'],
          message: fmt('editRule.selectValidOption'),
        })
      }

      // Warehouse required if ship to warehouse
      if (action === RuleAction.ShipToWarehouse && !warehouseId) {
        errors.push({
          field: ['warehouseId'],
          message: fmt('editRule.selectWarehouse'),
        })
      }

      // Message required
      if (action === RuleAction.DenyReturn && !message) {
        errors.push({
          field: ['message'],
          message: fmt('editRule.provideMessage'),
        })
      }

      // Number of days required
      if (action === RuleAction.AdjustWindow && !value) {
        errors.push({
          field: ['value'],
          message: fmt('editRule.specifyDays'),
        })
      }

      // Date until value required
      if (action === RuleAction.AllowReturnsUntilDate && !value) {
        errors.push({
          field: ['value'],
          message: fmt('global.required'),
        })
      }

      // Value required
      if (action === RuleAction.SetFee && !value.trim()) {
        errors.push({
          field: ['value'],
          message: fmt('global.required'),
        })
      }

      // Fail if any errors
      if (errors.length) return { status: 'fail', errors }

      const conditionList = validConditionFields.reduce((accumulator: any, currentValue) => {
        const condition = {
          id: currentValue.id.value || null,
          attr: currentValue.attr.value,
          op: currentValue.op.value,
          value: currentValue.value.value,
        }
        return [...accumulator, condition]
      }, [])

      const variables: RuleInput = {
        id: rule?.id,
        name,
        message: message ?? '',
        when: when,
        conditions: conditionList as ConditionInput[],
        action: action ?? RuleAction.DenyReturn,
        actionOption: actionOption ?? RuleActionOption.All,
        warehouseId: warehouseId,
        value,
      }

      if (rule) await updateReturnRule({ returnRule: variables })
      else await createReturnRule({ returnRule: variables })
      dispatch(showToast(fmt('rules.ruleSaved')))
      onComplete()
      return { status: 'success' }
    },
  })

  const conditionsErrors = useMemo(() => submitErrors.filter((e) => e.field?.includes('conditions')), [submitErrors])

  const [showDeleteModal, setShowDeleteModal] = useState(false)

  return (
    <FormLayout>
      <TextField
        label={fmt('editRule.nameThisRule')}
        labelHidden
        name="name"
        placeholder={fmt('editRule.nameThisRulePlaceholder')}
        autoComplete="off"
        {...fields.name}
      />
      <Bleed marginInline="400">
        <Divider />
        <Box background="bg-fill-secondary" padding="400" paddingBlockStart="300">
          <BlockStack gap="300">
            <Text as="h3" variant="headingXs">
              <FormattedMessage id="editRule.conditions" defaultMessage="Conditions" />
            </Text>
            <BlockStack gap="300">
              {!!conditionsErrors.length && (
                <FormLayout.Group>
                  {conditionsErrors.map((error) => (
                    <Box key={error.message}>
                      <InlineError message={error.message} fieldID="attr" />
                    </Box>
                  ))}
                </FormLayout.Group>
              )}
              {conditionFields.map((condition, index) => (
                <ConditionRow
                  key={index}
                  condition={condition}
                  addCondition={index === conditionFields.length - 1 ? () => addCondition() : undefined}
                  removeCondition={conditionFields.length > 1 ? () => removeCondition(index) : undefined}
                />
              ))}
            </BlockStack>
          </BlockStack>
        </Box>
        <Divider />
        {conditionFields.length > 1 && (
          <>
            <Box paddingInline="400" paddingBlock="300">
              <BlockStack gap="400">
                <Checkbox
                  label={
                    <FormattedMessage id="editRule.requireAllConditions" defaultMessage="All conditions must be met" />
                  }
                  helpText={
                    <>
                      {fields.when.value === RuleWhen.And ? (
                        <FormattedMessage
                          id="editRule.requireAllConditions.helpText.and"
                          defaultMessage="If checked, this rule will apply when <strong>all</strong> the conditions above are met."
                          values={{ strong: (content) => <strong>{content}</strong> }}
                        />
                      ) : (
                        <FormattedMessage
                          id="editRule.requireAllConditions.helpText.or"
                          defaultMessage="If not checked, this rule will apply when <strong>one or more</strong> of the conditions are met."
                          values={{ strong: (content) => <strong>{content}</strong> }}
                        />
                      )}
                    </>
                  }
                  name="when"
                  checked={fields.when.value === RuleWhen.And}
                  onChange={() => fields.when.onChange(fields.when.value === RuleWhen.And ? RuleWhen.Or : RuleWhen.And)}
                />
              </BlockStack>
            </Box>
            <Divider />
          </>
        )}
        <Box padding="400" paddingBlockStart="300">
          <BlockStack gap="300">
            <Text as="h3" variant="headingXs">
              {fmt('global.effect')}
            </Text>
            <FormLayout.Group>
              <TakeActionRow
                {...fields}
                showAdjustWindowAction={showAdjustWindowAction}
                showApprovalActions={showApprovalActions}
                showDoNotRequireShippingAction={showDoNotRequireShippingAction}
              />
            </FormLayout.Group>
            {fields.action.value === RuleAction.DenyReturn && (
              <FormLayout.Group>
                <TextField
                  name="ruleMessage"
                  label={fmt('editRule.ruleMessageLabel')}
                  autoComplete="off"
                  multiline={4}
                  {...fields.message}
                />
              </FormLayout.Group>
            )}
          </BlockStack>
        </Box>
        <Divider />
      </Bleed>
      <InlineStack align="space-between" blockAlign="center" gap="400">
        <div>
          {rule?.id && (
            <>
              <Button onClick={() => setShowDeleteModal(true)} variant="plain" tone="critical">
                {fmt('global.delete')}
              </Button>
              <Modal
                open={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                title={<FormattedMessage id="editRule.deleteReturnModal.title" defaultMessage="Delete rule?" />}
                titleHidden
                size="small"
                primaryAction={{
                  content: fmt('global.delete'),
                  destructive: true,
                  disabled: deleting,
                  loading: deleting,
                  onAction: handleDeleteRule,
                }}
                secondaryActions={[
                  {
                    disabled: deleting,
                    content: fmt('global.cancel'),
                    onAction: () => setShowDeleteModal(false),
                  },
                ]}
              >
                <Modal.Section>
                  <p>
                    <FormattedMessage
                      id="editRule.deleteReturnModal.body"
                      defaultMessage="Are you sure you want to delete this rule?"
                    />
                  </p>
                </Modal.Section>
              </Modal>
            </>
          )}
        </div>
        <InlineStack gap="200">
          <Button onClick={onComplete}>{fmt('global.cancel')}</Button>
          <Button submit onClick={submit} variant="primary">
            {fmt('global.save')}
          </Button>
        </InlineStack>
      </InlineStack>
    </FormLayout>
  )
}
export default EditRuleModal
