import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface CustomizerSlice {
  mode: 'test' | 'customizer'
}

const initialState: CustomizerSlice = {
  mode: 'test',
}

export const customizerSlice = createSlice({
  name: 'customizer',
  initialState,
  reducers: {
    setCustomizerMode: (state, { payload: mode }: PayloadAction<CustomizerSlice['mode']>) => {
      state.mode = mode
    },
  },
})

export const { setCustomizerMode } = customizerSlice.actions

export default customizerSlice.reducer
