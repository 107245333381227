import { createContext, FC, memo, ReactNode, useCallback, useMemo, useState } from 'react'

import { useViewportWidth } from 'common/useViewportWidth'

type ContextProps = {
  maxTopAreaHeight: number | undefined
  firstTopAreaHeight: number | undefined
  oneColumn: boolean
  reportTopAreaHeight: (index: number, ratio: number) => void
}

export const PlanCardContext = createContext<ContextProps>({
  maxTopAreaHeight: undefined,
  firstTopAreaHeight: undefined,
  oneColumn: false,
  reportTopAreaHeight: () => {},
})

export const PlanCardContextProvider: FC<{ children: ReactNode }> = memo(function PlanCardContextProvider({
  children,
}) {
  const [heights, setHeights] = useState<number[]>([])
  const reportTopAreaHeight = useCallback<ContextProps['reportTopAreaHeight']>(
    (index, ratio) =>
      setHeights((ratios) => {
        const newArray = ratios.slice()
        newArray[index] = ratio
        return newArray
      }),
    [],
  )
  const maxTopAreaHeight = useMemo(() => (heights.length ? Math.max(...heights) : undefined), [heights])
  const viewportWidth = useViewportWidth()
  const oneColumn = viewportWidth < 992
  const firstTopAreaHeight = heights[0]
  const value = useMemo(
    () => ({
      maxTopAreaHeight,
      firstTopAreaHeight,
      oneColumn,
      reportTopAreaHeight,
    }),
    [maxTopAreaHeight, firstTopAreaHeight, oneColumn],
  )
  return <PlanCardContext.Provider value={value}>{children}</PlanCardContext.Provider>
})
