import { combineReducers, configureStore, Reducer, Slice, ThunkAction, UnknownAction } from '@reduxjs/toolkit'
import { RMASlice } from 'rma/slice/rma.slice'

import customizerSlice from './customizer/customizer.slice'
import globalSlice from './global/global.slice'
import onboardingSlice from './onboarding/onboarding.slice'

const staticReducers = {
  global: globalSlice,
  onboarding: onboardingSlice,
  customizer: customizerSlice,
}

export const store = configureStore({
  reducer: staticReducers,
})

const loadedReducers: { [key: string]: Reducer } = {}

export const loadSlice = (slice: Slice) => {
  if (!(loadedReducers[slice.name] as Reducer | undefined)) loadedReducers[slice.name] = slice.reducer
  store.replaceReducer(combineReducers({ ...staticReducers, ...loadedReducers }))
}

export type RootState = ReturnType<typeof store.getState> & {
  rma?: RMASlice
}

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, UnknownAction>
