import { FC, PropsWithChildren, useEffect } from 'react'
import { IntlProvider as ReactIntlProvider } from 'react-intl'
import { selectLocale, selectMessages } from 'store/global/global.selectors'
import { setMessages } from 'store/global/global.slice'
import { _, useAppDispatch } from 'store/hooks'
import en from 'translations/en/admin.json'

import { getLocaleLoader } from './intl'

export const IntlProvider: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch()
  const locale = _(selectLocale)
  useEffect(() => {
    let aborted = false
    const load = async () => {
      const localeLoader = getLocaleLoader(locale)
      if (localeLoader) {
        const localeMessages = await localeLoader()
        if (!aborted) dispatch(setMessages({ locale, messages: { ...en, ...localeMessages } }))
      } else dispatch(setMessages({ locale, messages: en }))
    }
    load()
    return () => {
      aborted = true
    }
  }, [locale])
  const messages = _(selectMessages)
  return (
    <ReactIntlProvider locale={locale} messages={messages}>
      {children}
    </ReactIntlProvider>
  )
}
