import IMask from 'imask'

export const getDateMask = (initialValue: string) => {
  const dateMask = IMask.createMask({
    mask: 'Y-m-d',
    blocks: {
      d: {
        mask: IMask.MaskedRange,
        placeholderChar: 'd',
        from: 1,
        to: 31,
        maxLength: 2,
      },
      m: {
        mask: IMask.MaskedRange,
        placeholderChar: 'm',
        from: 1,
        to: 12,
        maxLength: 2,
      },
      Y: {
        mask: IMask.MaskedRange,
        placeholderChar: 'y',
        from: 1900,
        to: 9999,
        maxLength: 4,
      },
    },
  })
  if (initialValue) dateMask.value = initialValue
  return dateMask
}
