import { Loading } from '@shopify/polaris'
import { FC, lazy, memo } from 'react'
import { selectDataLoaded } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

import { PageRevealer } from 'common/PageRevealer'
import { useCommonData } from 'common/useCommonData'
import { useBillingQuery } from 'gql'
import { SkeletonSettings } from 'settings/SkeletonSettings'

import { PlanCardContextProvider } from './PlanCardContext'

const PlansPage = lazy(() => import('./PlansPage'))

type PlansPageLoaderProp = {
  force: boolean
}

export const PlansPageLoader: FC<PlansPageLoaderProp> = memo(function PlansPageLoader({ force }) {
  const [{ data, fetching, stale }] = useCommonData(useBillingQuery, 'billing')
  const loaded = _(selectDataLoaded('billing'))
  return (
    <>
      {(fetching || stale) && <Loading />}
      {!loaded && <SkeletonSettings />}
      <PageRevealer reveal={loaded}>
        <PlanCardContextProvider>
          <PlansPage force={force} subscriptionPlans={data?.subscriptionPlans} />
        </PlanCardContextProvider>
      </PageRevealer>
    </>
  )
})
