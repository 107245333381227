export const ONBOARDING_WIDTH = 960
export const ONBOARDING_HEIGHT = 640
export const ONBOARDING_CONTENT_WIDTH = 717
export const ONBOARDING_ILLUSTRATION_WIDTH = ONBOARDING_WIDTH - ONBOARDING_CONTENT_WIDTH

export const REGULAR_PADDING = 26

export const FLUID_WIDTH = 1024
export const FLUID_WIDTH_BREAKPOINT = `max-width: ${FLUID_WIDTH}px`

export const FLUID_HEIGHT = 670
export const FLUID_HEIGHT_BREAKPOINT = `max-height: ${FLUID_HEIGHT}px`

export const BORDER_COLOR = '#e7e7e7'

export const TRANSITION_TIME = '.3s'
export const TRANSITION_EASING = 'cubic-bezier(.4, 0, .1, 1)'

export const DEFAULT_EASING = 'cubic-bezier(.4, 0, .1, 1)'
